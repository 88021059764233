'use client';

import useAppLanguage from '../../../hooks/useAppLanguage';

interface Props {
  text?: string;
  subtext: string;
}

export default function OnboardHeader({ text = 'Welcome to Wonderschool!', subtext }: Props) {
  const { translate } = useAppLanguage();
  return (
    <div className="text-center space-y-6 max-sm:space-y-2">
      <div className="text-4xl max-sm:text-xl font-bold">{translate(text)}</div>
      <div className="text-xl max-sm:text-sm">
        <div>{translate(subtext)}</div>
      </div>
    </div>
  );
}
