'use client';

import React, { useEffect, useMemo } from 'react';

import useAuth from '../../hooks/useAuth';
import { checkHasBrandingData } from '../../utils/utils';

interface PageBaseProp extends React.PropsWithChildren {
  pageTitle: string;
  appName?: string; // Optional page title is to add additional title. e.g. pageTitle - Title
}

// TODO: figure out how to handle branding favicon replace

const PageBaseV2: React.FC<PageBaseProp> = ({
  // pageTitle,
  //  appName = 'Wonderschool',
  children,
}) => {
  const { brandingData } = useAuth();
  const hasBrandingData = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  // modify favicon if needed
  useEffect(() => {
    if (!hasBrandingData) {
      return;
    }

    let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    link.href = brandingData?.favicon?.href ?? '';
  }, [brandingData, hasBrandingData]);

  return (
    <div className="max-w-screen-sm mx-auto max-sm:px-2">
      <div>{children}</div>
    </div>
  );
};

export default PageBaseV2;
